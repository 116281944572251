.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.ColorMain {
  background-color: #784eff;
  color: #fff;
}

:where(.css-dev-only-do-not-override-htwhyh).ant-space {
  display: flex !important;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.site-layout-content {
  min-height: 280px;
  padding: 24px;
}

#components-layout-demo-top .logo {
  float: left;
  width: 120px;
  height: 31px;
  margin: 16px 24px 16px 0;
  background: rgba(255, 255, 255, 0.3);
}

.ant-row-rtl #components-layout-demo-top .logo {
  float: right;
  margin: 16px 0 16px 24px;
}

.container {
  max-width: 1100px;
  margin: auto;
  /* text-align: center; */
}

.site-layout {
  margin-top: 20px;
}

.spacevertical {
  display: flex;
  max-width: 75px;
}

.infasd {
  max-width: 30px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

/* 外壳样式 */
.table-enter-leave-demo-wrapper {
  position: relative;
  background: #ABC3FF;
  overflow: hidden;
  height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.table-enter-leave-demo {
  box-shadow: 0 10px 40px #506CB2;
  width: 60%;
  min-width: 550px;
  height: 440px;
  background: #F4F4F4;
  border-radius: 6px;
  overflow: hidden;
}

.table-enter-leave-demo-header {
  height: 32px;
  line-height: 32px;
  background: #D8D8D8;
}

.table-enter-leave-demo-header ul li {
  list-style: none;
  width: 8px;
  height: 8px;
  display: inline-block;
  border-radius: 4px;
  margin: 0 5px 0 0;
}

.table-enter-leave-demo-header ul li:nth-child(1) {
  background: #CF3333;
  margin-left: 3%;
}

.table-enter-leave-demo-header ul li:nth-child(2) {
  background: #DEC811;
}

.table-enter-leave-demo-header ul li:nth-child(3) {
  background: #28C026;
}

.table-enter-leave-demo-header ul li:nth-child(4) {
  background: #fff;
  width: 80%;
  height: 16px;
  vertical-align: middle;
  margin: 0 10px;
}

.table-enter-leave-demo-header ul li:nth-child(5),
.table-enter-leave-demo-header ul li:nth-child(5):before,
.table-enter-leave-demo-header ul li:nth-child(5):after {
  width: 20px;
  height: 2px;
  background: #BABABA;
  border-radius: 2px;
}

.table-enter-leave-demo-header ul li:nth-child(5) {
  vertical-align: middle;
  margin-left: 5px;
  position: relative;
}

.table-enter-leave-demo-header ul li:nth-child(5):before,
.table-enter-leave-demo-header ul li:nth-child(5):after {
  content: '';
  display: block;
  position: absolute;
}

.table-enter-leave-demo-header ul li:nth-child(5):before {
  top: -6px;
}

.table-enter-leave-demo-header ul li:nth-child(5):after {
  top: 6px;
}

/* 外壳 end */

.table-enter-leave-demo-nav {
  height: 50px;
  background: #080B20;
  line-height: 50px;
}

.table-enter-leave-demo-nav span {
  margin-left: 20px;
}

.table-enter-leave-demo-nav img:first-child {
  filter: grayscale(1) brightness(3);
}

.table-enter-leave-demo-nav img:last-child {
  margin-left: 10px;
}

.table-enter-leave-demo-list {
  width: 20%;
  height: calc(100% - 82px);
  display: inline-block;
  background: #171C3E;
}

.table-enter-leave-demo-list ul li {
  width: 80%;
  height: 15px;
  background: #23274A;
  margin: 20px auto;
  border-radius: 4px;
}

.table-enter-leave-demo-table-wrapper {
  width: 80%;
  display: inline-block;
  overflow: auto;
  height: calc(100% - 82px);
}

.table-enter-leave-demo-action-bar {
  margin: 20px;
  background: #fff;
  line-height: 40px;
  height: 40px;
  position: relative;
  border-radius: 4px;
}

.table-enter-leave-demo-action-bar:before,
.table-enter-leave-demo-action-bar:after {
  content: '';
  display: inline-block;
  background: #E5E5E5;
  vertical-align: middle;
}

.table-enter-leave-demo-action-bar:before {
  width: 20px;
  height: 20px;
  border-radius: 100%;
  margin-left: 10px;
}

.table-enter-leave-demo-action-bar:after {
  position: absolute;
  width: 100px;
  height: 10px;
  top: 0;
  bottom: 0;
  margin: auto;
  left: 40px;
}

.table-enter-leave-demo-action-bar button {
  position: absolute;
  right: 10px;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 80px;
  height: 20px;
  border-radius: 4px;
  line-height: 1;
}

.table-enter-leave-demo-delete {
  color: #019BF0;
  cursor: pointer;
}

.table-enter-leave-demo-table {
  margin: 0 20px;
  overflow: hidden;
}

.table-enter-leave-demo-table .ant-table-thead>tr>th {
  background: #E5E5E5;
  padding: 10px 8px;
  display: inline-block;
}

.table-enter-leave-demo-table .ant-table-tbody>tr {
  background: #fff;
  /* 不能用
  border-bottom: 1px solid #e9e9e9;
  */
}

.table-enter-leave-demo-table .ant-table-tbody>tr>td {
  padding: 10px 8px;
  display: inline-block;
  vertical-align: bottom;
}


.table-enter-leave-demo-table .ant-table-thead>tr,
.table-enter-leave-demo-table .ant-table-tbody>tr {
  display: block;
}

.table-enter-leave-demo-table .ant-table-thead>tr,
.table-enter-leave-demo-table .ant-table-tbody>tr {
  transition: none;
}

.table-enter-leave-demo-table .ant-table-thead>tr>th:nth-child(1),
.table-enter-leave-demo-table .ant-table-tbody>tr>td:nth-child(1) {
  width: 25%;
}

.table-enter-leave-demo-table .ant-table-thead>tr>th:nth-child(2),
.table-enter-leave-demo-table .ant-table-tbody>tr>td:nth-child(2) {
  width: 15%;
}

.table-enter-leave-demo-table .ant-table-thead>tr>th:nth-child(3),
.table-enter-leave-demo-table .ant-table-tbody>tr>td:nth-child(3) {
  width: 40%;
}

.table-enter-leave-demo-table .ant-table-thead>tr>th:nth-child(4),
.table-enter-leave-demo-table .ant-table-tbody>tr>td:nth-child(4) {
  width: 20%;
}

@media screen and (max-width: 414px) {
  .table-enter-leave-demo {
    transform: scale(.65) translateX(12px);
    transform-origin: left center;
  }
}

@media screen and (max-width: 375px) {
  .table-enter-leave-demo {
    transform: scale(.6) translateX(7px);
  }
}

@media screen and (max-width: 320px) {
  .table-enter-leave-demo {
    transform: scale(.5) translateX(12px);
  }
}

body h1 {
  font-weight: 300;

  color: #000;
}

body h3 {
  font-weight: 600;
  color: #555;
}

body a {
  color: #714BEC;
}

.disocs {
  display: flex;
}

.invoice-box {
  max-width: 800px;
  margin: auto;
  padding: 30px;
  /* border: 1px solid #eee; */
  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.15); */
  font-size: 16px;
  line-height: 24px;
  font-family: 'Lexend';
  color: #555;
}

.invoice-box table {
  width: 100%;
  line-height: inherit;
  text-align: left;
  border-collapse: collapse;
}

.invoice-box table td {
  padding: 5px;
  vertical-align: top;
}

.invoice-box table tr td:nth-child(2) {
  text-align: right;
}

.invoice-box table tr.top table td {
  padding-bottom: 20px;
}

.invoice-box table tr.top table td.title {
  font-size: 45px;
  line-height: 45px;
  color: #333;
}

.invoice-box table tr.information table td {
  padding-bottom: 40px;
}

.invoice-box table tr.heading td {
  background: #eee;
  border-bottom: 1px solid #ddd;
  font-weight: bold;
}

.invoice-box table tr.details td {
  padding-bottom: 20px;
}

.invoice-box table tr.item td {
  border-bottom: 1px solid #eee;
}

.invoice-box table tr.item.last td {
  border-bottom: none;
}

.invoice-box table tr.total td:nth-child(2) {
  border-top: 2px solid #eee;
  font-weight: bold;
}

@media only screen and (max-width: 600px) {
  .invoice-box table tr.top table td {
    width: 100%;
    display: block;
    text-align: center;
  }

  .invoice-box table tr.information table td {
    width: 100%;
    display: block;
    text-align: center;
  }
}

#components-form-demo-normal-login .login-form {
  max-width: 300px;
}

#components-form-demo-normal-login .login-form-forgot {
  float: right;
}

#components-form-demo-normal-login .ant-col-rtl .login-form-forgot {
  float: left;
}

:where(.css-dev-only-do-not-override-htwhyh).ant-menu-inline.ant-menu-root .ant-menu-item>.ant-menu-title-content,
:where(.css-dev-only-do-not-override-htwhyh).ant-menu-inline.ant-menu-root .ant-menu-submenu-title>.ant-menu-title-content {
  flex: none;
}

.contetn {
  position: relative;
  display: flex;
  margin: auto;
  height: 93vh;
}

.bg-login {
  background: linear-gradient(-45deg, #ee7752, #e73c7e, #2352d5, #23d5ab);
  background-size: 400% 400%;
  animation: gradient 15s ease infinite;
  margin: 0;
  padding: 0;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

.login-form {
  margin: auto;
  padding: 40px;
  border-radius: 10px;
  background: #ffffff;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.login-formloga {
  position: relative;
  display: flex;
  margin: auto;
  flex-direction: column;
  align-items: center;
  gap: 18px;
}

.login-form-footer {
  background-color: transparent;
  margin-bottom: 0;
  color: #ccc;
  display: flex;
  justify-content: space-between;
}

.editable-row .ant-form-item-explain {
  position: absolute;
  top: 100%;
  font-size: 12px;
}

.events {
  margin: 0;
  padding: 0;
  list-style: none;
}

.events .ant-badge-status {
  width: 100%;
  overflow: hidden;
  font-size: 12px;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.notes-month {
  font-size: 28px;
  text-align: center;
}

.notes-month section {
  font-size: 28px;
}

.container-Drawer {
  margin: auto;
  padding: 20px;
}

.select {
  width: 100%;
  height: 40px;
  border-radius: 4px;
  border: 1px solid #ccc;
  padding: 0 10px;
  font-size: 16px;
  color: #333;
  outline: none;
}

.loading {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
}

#components-form-demo-control-hooks .ant-btn {
  margin-right: 8px;
}

.ID-USER {
  display: flex;
  justify-content: flex-end;
}

.site-form-in-drawer-wrapper {
  position: absolute;
  right: 0px;
  bottom: 0px;
  width: 100%;
  min-width: 700px;
  padding: 10px 16px;
  text-align: right;
  background: #fff;
  border-top: 1px solid #e9e9e9;
}

.card-chart {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0 123px;
  justify-content: center;
  align-content: center;
  flex-wrap: wrap;
}

.margin-chart {
  margin: 5px;
  max-width: 628px;
  padding: 10px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(224, 224, 224);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #dddddd;
}

/* General Print Styles */
@media print {
  body {
      font-family: Arial, sans-serif;
      font-size: 12pt;
      color: #000;
      margin: 0;
      padding: 20px 25px 20px 45px;
      background: #fff;
  }

  /* Hide elements that should not appear in print */
  .no-print, .no-print * {
      display: none !important;
  }

  /* Ensure tables print properly */
  table {
      width: 100%;
      border-collapse: collapse;
      margin-bottom: 20px;
  }

  table, th, td {
      /* border: 1px solid #000; */
      padding: 8px;
  }

  th {
      background-color: #f2f2f2;
      text-align: left;
  }

  /* Make sure links print properly */
  a {
      color: #000;
      text-decoration: none;
  }

  a::after {
      content: " (" attr(href) ")";
  }

  /* Headings should stand out more in print */
  h1, h2, h3 {
      font-size: 16pt;
      margin-bottom: 10px;
  }

  /* Page breaks between print sections */
  .page-break {
      page-break-before: always;
  }

  /* Ensure images scale properly */
  img {
      max-width: 100%;
      height: auto;
  }

  /* Add any custom margins or padding to the printed page */
  .print-container {
      margin: 0 auto;
      padding: 20px;
      width: 100%;
  }
}

