body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Lexend';
}


h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Lexend';
  font-style: normal;
  margin: 0 !important;
}

a {
  text-decoration: none !important;
  color: #000;
}

h1 {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 33px;
  text-align: center;
}

.bold {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 33px;
  color: #080808;
}

@media screen and (min-width: 901px) {
  body {
    /* background-image: url("../src/1024.jpg"); */
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
  }
}

@media screen and (max-width: 900px) {
  body {
    background-image: none;
    background-color: #fff;
    display: none;

  }

  /* diğer özellikler */
}

.site-description-item-profile-wrapper {
  margin-bottom: 7px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  line-height: 1.5715;
}

.ant-drawer-body p.site-description-item-profile-p {
  display: block;
  margin-bottom: 16px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 16px;
  line-height: 1.5715;
}

.site-description-item-profile-p-label {
  display: inline-block;
  margin-right: 8px;
  color: rgba(0, 0, 0, 0.85);
}